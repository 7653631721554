// import jQuery from 'jquery';
// import './pwa-install';
// window.$ = window.jQuery = jQuery;
// import './modernizr-custom';
// core version + navigation, pagination modules:
import Swiper, { Pagination, EffectFade, Navigation, HashNavigation } from 'swiper';
// import Swiper and modules styles
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
// import '../css/index.scss';
import '../css/swiper_custom.scss';

// configure Swiper to use modules
Swiper.use([Pagination, EffectFade, Navigation, HashNavigation]);

// if (process.env.NODE_ENV === 'production') {
//     console.log('Happy developing!');
// }

// navigator.serviceWorker.register(
//     new URL('service-worker.js', import.meta.url),
//     { type: 'module' }
// );


screenDetectTasks();
// init Swiper:
const swiper = new Swiper('#main', {
    // Optional parameters
    allowTouchMove: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    speed: 800,
    loop: false,
    // autoplay: {
    //     delay: 5000,
    // },
    hashNavigation: {
        watchState: true,
    },
    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar'
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});


function screenDetectTasks() {
    // viewport and full window dimensions will change
    let viewport_width = window.innerWidth;
    let bodyElement = document.querySelector('body');
    // console.log(viewport_width);
    if (viewport_width <= 414) {
        //Is Mobile
        bodyElement.classList.add('mobile');

    }

    if (viewport_width > 415 && viewport_width <= 991) {
        //Is Tablet
        bodyElement.classList.add('tablet');
    }

    if (viewport_width >= 992) {
        //Is Desktop
        bodyElement.classList.add('desktop');
    }
}


